var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.category
    ? _c(
        "div",
        { attrs: { id: "header" } },
        [
          _vm.category.thumbnail_url
            ? _c(
                "div",
                { staticClass: "embed-responsive embed-responsive-21by8" },
                [
                  _c("div", { staticClass: "embed-responsive-item" }, [
                    _c("img", { attrs: { src: _vm.category.thumbnail_url } })
                  ])
                ]
              )
            : _vm._e(),
          _c(
            "b-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "b-row",
                [
                  _c("b-col", { staticClass: "py-4 py-lg-5" }, [
                    _c("h1", { staticClass: "text-gray-dark m-0" }, [
                      _vm._v(_vm._s(_vm.category.title))
                    ])
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }