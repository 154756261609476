import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    activeAsset: null,
    asset: null,
    assets: null,
    category: null,
    loading: false,
    modalAsset: null,
    password: null,
    pinnedAsset: null,
    type: null
  },
  getters: {
    activeAsset: state => {
      return state.activeAsset;
    },
    asset: state => {
      return state.asset;
    },
    assets: state => {
      return state.assets;
    },
    category: state => {
      return state.category;
    },
    loading: state => {
      return state.loading;
    },
    modalAsset: state => {
      return state.modalAsset;
    },
    password: state => {
      return state.password;
    },
    pinnedAsset: state => {
      return state.pinnedAsset;
    },
    type: state => {
      return state.type;
    }
  },
  mutations: {
    activeAsset: (state, data) => {
      state.activeAsset = data;
    },
    asset: (state, data) => {
      state.asset = data;
    },
    assets: (state, data) => {
      state.assets = data;
    },
    category: (state, data) => {
      state.category = data;
    },
    loading: (state, data) => {
      state.loading = data;
    },
    modalAsset: (state, data) => {
      state.modalAsset = data;
    },
    password: (state, data) => {
      state.password = data;
    },
    pinnedAsset: (state, data) => {
      state.pinnedAsset = data;
    },
    type: (state, data) => {
      state.type = data;
    },
  },
  actions: {
    activeAsset({ commit }, data) {
      commit('activeAsset', data);
    },
    asset({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('loading', true);
        let url = data.password
                ? `public/assets/${data.asset_slug}?api_key=${process.env.VUE_APP_API_KEY}&password=${data.password}`
                : `public/assets/${data.asset_slug}?api_key=${process.env.VUE_APP_API_KEY}`
        Vue.axios.get(url)
          .then((response) => {
            commit('asset', response.data);
            commit('loading', false);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    embedd({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('loading', true);
        let url = `public/assets/embed/${data.slug}?api_key=${process.env.VUE_APP_API_KEY}`
        Vue.axios.get(url)
          .then((response) => {
            commit('asset', response.data);
            commit('loading', false);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    assets({ commit }, data) {
      return new Promise((resolve, reject) => {
        let url = data.password
                ? `public/assets?api_key=${process.env.VUE_APP_API_KEY}&password=${data.password}`
                : `public/assets?api_key=${process.env.VUE_APP_API_KEY}`
        Vue.axios.get(url, {
          params: {
            category_id: data.category_id ? data.category_id : null,
            types: data.types ? data.types : null
          }
        })
        .then((response) => {
          const assets = response.data;
          assets.forEach((asset) => {
            const cf = asset.customfields;
            cf.forEach((field) => {
              if (field.slug === 'pinned-1' && field.value === 1) {
                commit('pinnedAsset', asset);
              }
            })
          })
          commit('assets', assets);
          resolve(assets);
        })
        .catch((error) => {
          reject(error);
        });
      });
    },
		category({ commit, state }, data) {
			return new Promise((resolve, reject) => {
				let url = `public/categories/${data.slug}`;
				Vue.axios.get(url, {
					params: {
						api_key : !state.type.uuid ? process.env.VUE_APP_API_KEY : null,
						password : data.password ? data.password : null
					}
				})
				.then((response) => {
					commit('category', response.data)
					resolve(response.data)
				})
				.catch((error) => {
					reject(error)
				})
			})
		},
    modalAsset({ commit }, data) {
      commit('modalAsset', data);
    },
    type({ commit }, slug) {
			return new Promise((resolve, reject) => {
				Vue.axios.get(`public/type/${slug}?api_key=${process.env.VUE_APP_API_KEY}`)
				.then((response) => {
					commit('type', response.data)
					resolve(response.data)
				})
				.catch((error) => {
					reject(error)
				})
			})
		}
  }
})
