import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
  const routes = [
    {
      path: '/i/:slug?',
      name: 'embed',
      component: () => import(/* webpackChunkName: "bundle" */ '../views/Embed.vue')
    },
    {
      path: '/:a?/:b?/:c?/:d?/:e?/:f?/:g?',
      name: 'category',
      component: () => import(/* webpackChunkName: "bundle" */ '../views/Category.vue')
    },

]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
