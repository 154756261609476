<template>
	<div id="app">
		<Header/>
		<router-view class="main"/>
	</div>
</template>
<style lang="scss">
	@import '@/assets/css/default.scss';
</style>
<script>
	import Header from '@/components/Header.vue'
	export default {
		name: 'App',
		metaInfo: {
			title: 'SEB Internal',
			titleTemplate: '%s | SEB'
		},
		components: {
			Header
		},
	}
</script>
