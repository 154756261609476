<template>
	<div id="header" v-if="category">
		<div class="embed-responsive embed-responsive-21by8" v-if="category.thumbnail_url">
			<div class="embed-responsive-item">
				<img :src="category.thumbnail_url">
			</div>
		</div>
		<b-container fluid>
			<b-row>
				<b-col class="py-4 py-lg-5">
					<h1 class="text-gray-dark m-0">{{ category.title }}</h1>
				</b-col>
			</b-row>
		</b-container>
	</div>
</template>
<script>
	export default {
		computed: {
			category() {
				return this.$store.getters.category
			}
		}
	}
</script>
